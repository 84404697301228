import type {FragmentMenuFragment} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import Link from "next/link";

interface FooterNavSectionProps {
  children?: React.ReactNode;
  className?: string;
  columns?: 1 | 2;
  navigation?: FragmentMenuFragment;
  title?: string;
}

export const FooterNavSection = ({
                                   title,
                                   navigation,
                                   className,
                                   columns,
                                 }: FooterNavSectionProps) => {
  const displayTitle = title ?? "";
  return (
    <div className={cn(className)}>
      {displayTitle && <p className="h6 footer-nav-title">{displayTitle}</p>}
      <ul className={cn("space-y-3 2xm:space-y-4", columns === 2 ? "lg:columns-2" : "")}>
        {navigation?.items?.map((item, index) => (
          <li
            key={index}
          >
            <Link href={item.url} target={item.internal ? "_self" : "_blank"} className="text-white hover:underline transition-all flex-grow" prefetch={false}>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
