import { type FieldErrors } from "react-hook-form";

const findInObject = (obj: FieldErrors, i: string) => obj?.[i];

const useFormError = (name: string, errors: FieldErrors) => {
  const error = name
    .replace(/]/g, "")
    .replace(/\[/g, ".")
    .split(".")
    .reduce(findInObject, errors);

  return typeof error?.message === "string" ? error.message : undefined;
};

export default useFormError;
