import Form from "@/components/forms/Form";
import {useZodForm} from "@/hooks/useZodForm";
import {z} from "zod";
import {AllowedGeneralSearchParams} from "@/types/generic";
import dynamic from "next/dynamic";
import {SearchQueryInput} from "@/components/forms/controls/SearchQueryInput";
import Button from "@/components/atoms/Button";
import Icon from "@/components/atoms/Icon";
import {Fragment, useEffect} from "react";
import {usePathname} from "next/navigation";
import {Dialog, Transition} from "@headlessui/react";

const schema = z.object({
    [AllowedGeneralSearchParams.Query]: z.string().optional(),
});

const NoSSRSearchQueryInput = dynamic(async () => SearchQueryInput, {
    ssr: false,
});

interface SearchElementProps {
    className?: string;
    searchOpen: boolean;
    setSearchOpen: (open: boolean) => void;
}

export const SearchElement = ({setSearchOpen, searchOpen}: SearchElementProps) => {
    const pathname = usePathname();
    const form = useZodForm({schema});
    useEffect(() => {
        setSearchOpen(false);
    }, [pathname, setSearchOpen]);

    return (
        <div>
            <button
                type="button"
                aria-label={"Open zoekformulier"}
                className="btn inline-flex h-8 w-8 items-center justify-center"
                onClick={() => setSearchOpen(true)}
            >
                <span className="sr-only">Open zoekformulier</span>
                <span className="flex w-5 h-5 flex-col">
                <Icon name="search" className="h-5 w-5 group-hover:!fill-white transition-colors"/>
              </span>
            </button>
            <Transition.Root show={searchOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50"
                    onClose={setSearchOpen}
                >
                    <div className="fixed inset-0 z-50 bg-grey/80 backdrop-blur animate-fadeIn" />
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-150 sm:duration-300"
                        enterFrom="-translate-y-full"
                        enterTo="translate-y-0"
                        leave="transform transition ease-in-out duration-150"
                        leaveFrom="translate-y-0"
                        leaveTo="-translate-y-full"
                    >
                        <Dialog.Panel className="fixed inset-x-0 top-0 z-50 w-screen overflow-y-auto bg-white sm:ring-1 sm:ring-gray-900/10 shadow-lg h-[var(--header-height)]">
                            <div className="container mx-auto h-full">
                                <div className="flex items-center gap-2 h-full">
                                    <Form
                                        form={form}
                                        action={`/nl/zoeken`}
                                        method="GET"
                                        role="search"
                                        noValidate={true}
                                        className="relative z-1 flex flex-1 h-full"
                                    >

                                        <div className="w-full h-full relative">
                                            <NoSSRSearchQueryInput
                                                className="h-full w-full main-search-input"
                                                i18n={{
                                                    clear_recent_searches: "Clear recent searches",
                                                    label: "Zoekformulier invulveld",
                                                    new: "search_query.new",
                                                    placeholder: "Start met zoeken",
                                                    recent_searches: "search_query.recent_searches",
                                                }}
                                                searchHistoryBaseURL={`/nl/zoeken`}
                                            />

                                            <Button
                                                type="submit"
                                                className={"btn w-8 h-8 lg:h-12 lg:w-12 hover:lg:!text-blue p-0 inline-flex items-center justify-center rounded-full absolute top-1/2 right-2 lg:right-1 -translate-y-1/2 transition-colors"}
                                                as="button"
                                                arrow={false}
                                                hasLoader={false}
                                            >
                                                <span className="sr-only">Start zoekactie</span>
                                                <Icon name="search" className="h-5 w-5" />
                                            </Button>
                                        </div>
                                    </Form>
                                    <button
                                        type="button"
                                        className="btn h-8 lg:h-12 w-8 lg:w-12 p-0 text-grey rounded-full hover:lg:bg-grey hover:lg:text-white"
                                        onClick={() => setSearchOpen(false)}
                                    >
                                        <span className="sr-only">Sluit zoekformulier</span>
                                        <Icon name="close" className="h-5 w-5" />
                                    </button>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </Dialog>
            </Transition.Root>
        </div>
    );
};
