"use client";

import {FooterNavSection} from "@/components/blocks/FooterNavSection";
import {FooterBottomNavSection} from "@/components/blocks/FooterBottomNavSection";
import {LegalNavSection} from "@/components/blocks/LegalNavSection";
import {PartnersNavSection} from "@/components/blocks/PartnersNavSection";
import {FooterSocialLinks} from "@/components/blocks/FooterSocialLinks";
import {
  FragmentMenuFragment,
  FragmentMenuPartnersFragment,
  FragmentSocialMediaLinksItemFragment,
} from "@/graphql/sdk/__generated__";
import {Logo} from "@/components/atoms/Logo.tsx";
import Link from "next/link";

interface FooterProps {
  footerMenu?: FragmentMenuFragment;
  footerLegal?: FragmentMenuFragment;
  footerPartners?: FragmentMenuPartnersFragment;
  footerProfessional?: FragmentMenuPartnersFragment;
  footerContactVrt?: FragmentMenuPartnersFragment;
  footerAboutVrt?: FragmentMenuPartnersFragment;
  footerDocuments?: FragmentMenuPartnersFragment;
  socialMediaLinksItems? : FragmentSocialMediaLinksItemFragment
}

export const Footer = ({
                         footerMenu,
                         footerLegal,
                         footerPartners,
                         footerDocuments,
                         footerProfessional,
                         footerContactVrt,
                         footerAboutVrt,
                         socialMediaLinksItems
                       }: FooterProps) => {
  const nav_count = [
    footerContactVrt,
    footerDocuments,
    footerProfessional,
    footerAboutVrt
  ].filter(nav => nav?.items?.length > 0).length;
  const getClassName = (nav_count) => {
    if (nav_count === 4) {
      return 'col-span-full sm:col-span-6 lg:col-span-3';
    } else if (nav_count === 3) {
      return 'col-span-full sm:col-span-6 lg:col-span-4';
    } else {
      return 'col-span-full sm:col-span-6';
    }
  };

  const className = getClassName(nav_count);

  return (

    <footer className="footer mt-20" role="contentinfo">
      <div className="container mx-auto mb-6">
        <div className="bg-blue-500 rounded-xl p-6 lg:p-10 xl:p-12 io-grid text-white gap-y-6 w-full">
          {footerContactVrt?.items?.length > 0 && (
            <div className={className}>
              <FooterNavSection title={footerContactVrt?.name} navigation={footerContactVrt}/>
            </div>
          )}

          {footerDocuments?.items?.length > 0 && (
          <div className={className}>
              <FooterNavSection title={footerDocuments?.name} navigation={footerDocuments}/>
            </div>
          )}

          {footerProfessional?.items?.length > 0 && (
            <div className={className}>
              <FooterNavSection title={footerProfessional?.name} navigation={footerProfessional}/>
            </div>
          )}

          {footerAboutVrt?.items?.length > 0 && (
            <div className={className}>
              <FooterNavSection title={footerAboutVrt?.name} navigation={footerAboutVrt}/>
            </div>
          )}

          <div className="col-span-full lg:col-span-4 xl:col-span-6 lg:mt-6 xl:mt-10">
              <div className="hover:text-white/60 transition-colors">
                  <Link href={`/`}>
                      <Logo srText="VRT (home)" />
                  </Link>
              </div>
          </div>

          <div className="col-span-full lg:col-span-8 xl:col-span-6 lg:mt-6 xl:mt-10 flex flex-col md:flex-row md:items-center lg:justify-end lg:gap-5">
            <p className="md:!my-auto font-semibold h6">Tot op de socials!</p>
            <FooterSocialLinks socialMediaLinksItems={socialMediaLinksItems}/>
          </div>
        </div>
      </div>

      <div className="py-3">
        <div className="container mx-auto io-grid pb-10">
          <div className="col-span-full lg:col-span-6 xl:col-span-5 2xl:col-span-4">
            {footerMenu?.items?.length > 0 && (
              <FooterBottomNavSection navigation={footerMenu}/>
            )}
            {footerLegal?.items?.length > 0 && (
              <LegalNavSection title={footerLegal?.name} navigation={footerLegal}/>
            )}
          </div>
          {footerPartners?.items?.length > 0 && (
            <div className="col-span-full lg:col-span-6 xl:col-span-7 2xl:col-span-8">
              <PartnersNavSection navigation={footerPartners}/>
            </div>
          )}
        </div>
      </div>

    </footer>
  );
};
