
export enum AllowedGeneralSearchParams {
  Query = "query",
}

export interface StandardPageProps {
  params: {
    path: string[];
  };
  searchParams: Record<string, string>
}

export interface PreviewPageProps {
  params: {
    uuid: string[];
  };
  searchParams: Record<string, string>
}

export interface FilterValue {
  count?: number;
  value: string;
}

export interface FilterType {
  name: string;
  values: FilterValue[];
}


export enum AllowedFaqOverviewSearchParams {
  Language = "language",
  Page = "pagina",
  Search = "zoeken",
}

export enum AllowedSearchOverviewSearchParams {
  Page = "pagina",
  Search = "zoeken",
  Type = "type",
}

export enum AllowedNewsOverviewSearchParams {
  Page = "pagina",
  Themas = "themas",
  Labels = "labels",
  Merken = "merken",
}

export enum AllowedFaqOverviewSearchParams {
  Merken = "merken",
  Labels = "labels",
}
