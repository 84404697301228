import Link from "next/link";
import Icon from "@/components/atoms/Icon";
import {cn} from "@/helpers/className";

interface SocialLinkProps {
  className?: string;
  type: "twitter" | "linkedin" | "facebook" | "youtube" | "instagram";
  url: string;
}

export const SocialLink = ({url, className, type}: SocialLinkProps) => {
  return (
    <Link
      href={url}
      className={cn(
        "inline-flex h-10 w-10 shrink-0 items-center justify-center transition-colors text-white hover:text-white/60",
        className,
      )}
      title={type}
      aria-label={type}
      prefetch={false}
      target={"_blank"}
    >
      <Icon name={type} className="h-5 w-5"/>
    </Link>
  );
};
