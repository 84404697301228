import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, type UseFormProps } from "react-hook-form";
import { type TypeOf, type ZodSchema } from "zod";

interface UseZodFormProps<Z extends ZodSchema>
  extends Exclude<UseFormProps<TypeOf<Z>>, "resolver"> {
  schema?: Z;
}

export const useZodForm = <Z extends ZodSchema>(
  props: UseZodFormProps<Z> = {},
) => {
  const { schema, ...formProps } = props;
  const resolver = schema ? zodResolver(schema) : undefined;
  return useForm<TypeOf<Z>>({ resolver, ...formProps });
};
