"use client";

import { useState, useEffect, useRef, Fragment } from "react";
import Link from "next/link";
import { Transition } from "@headlessui/react";
import Icon from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";
import { MenuItem } from "@/graphql/sdk/__generated__";
import { SvgLink } from "@/components/atoms/SvgLink.tsx";

interface SubNavGroupProps {
  className?: string;
  clickToOpen?: boolean;
  itemClassName?: string;
  item: MenuItem;
  linkClassName?: string;
  subNav: MenuItem[];
  subNavClassName?: string;
  viewMode?: string;
}

export const SubNavGroup = ({
                              className,
                              clickToOpen,
                              item,
                              itemClassName,
                              linkClassName,
                              subNav,
                              subNavClassName,
                              viewMode
                            }: SubNavGroupProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  const subNavOpenClass = 'sub-nav-open';
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
      document.documentElement.style.overflow = 'hidden';
      document.body.classList.add(subNavOpenClass);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      document.documentElement.style.overflow = '';
      document.body.classList.remove(subNavOpenClass);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
      document.documentElement.style.overflow = '';
      document.body.classList.remove(subNavOpenClass);
    };
  }, [isOpen]);

  return (
    <div
      className={cn(viewMode, "has-sub-nav h-full", isOpen ? "is-active" : "")}
      ref={ref}
    >
      <button
        className={cn(
          "inline-flex items-center gap-2 max-lg:w-full max-lg:font-semibold max-lg:border-b max-lg:pb-4",
          itemClassName,
        )}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        type="button"
      >
        <span
          className={cn(
            "main-nav-button max-lg:flex max-lg:w-full max-lg:items-center lg:!gap-1",
            isOpen ? "lg:!text-white lg:!bg-blue" : ""
          )}
        >
          {item.title}
          <Icon
            name="arrow-vrt"
            className={cn(
              "h-6 w-6 lg:h-4 lg:w-4 max-lg:ml-auto transition-transform",
              isOpen ? "lg:-rotate-90" : "lg:rotate-90"
            )}
          />
        </span>
      </button>

      {isOpen && (
        <div
          className="max-lg:!hidden backdrop-overlay fixed inset-x-0 bottom-0 -z-10 bg-grey/80 backdrop-blur animate-[fadeIn_both_.3s] top-[var(--header-height)] h-[calc(100vh-var(--header-height))]"
          onClick={() => setIsOpen(false)}
        />
      )}

      <Transition
        as={Fragment}
        show={isOpen}
        unmount={false}
        enter="transition ease-out duration-150 sm:duration-300 lg:duration-200"
        enterFrom="opacity-0 max-lg:translate-x-full lg:translate-y-0"
        enterTo="lg:opacity-100 max-lgtranslate-y-0 lg:translate-x-0"
        leave="max-lg:transform transition ease-in-out duration-150 sm:duration-300 lg:duration-[.07s]"
        leaveFrom="max-lg:translate-x-0 lg:opacity-100 lg:translate-y-0"
        leaveTo="max-lg:translate-x-full lg:opacity-0 lg:translate-y-0"
      >
        <div
          className={cn(
            viewMode,
            "sub-nav",
            subNavClassName,
            "max-lg:fixed max-lg:pb-6 sm:max-w-sm lg:max-w-none max-lg:max-w-[100vw] max-lg:overflow-x-hidden max-lg:inset-y-0 max-lg:right-0 max-lg:z-50 max-lg:w-full max-lg:overflow-y-auto max-lg::max-w-sm max-lg:ring-1 max-lg:ring-gray-900/10",
            viewMode === "dark" ? "sub-nav--dark bg-midnight" : "sub-nav--default bg-white"
          )}
        >
          <div className="flex items-center lg:hidden justify-between gap-4 h-[var(--header-height)]">
            <button
              type="button"
              className="mr-auto group inline-flex border-2 border-white/60 bg-midnight transition-all text-white items-center justify-center ml-4 rounded-full w-9 h-9 mb-4"
              onClick={() => setIsOpen(false)}
            >
              <span className="sr-only">Vorige navigatie</span>
              <Icon name="arrow-left" className="h-4 w-4" />
            </button>
          </div>
          <div className={cn(
            "w-full container mx-auto flex flex-col mb-2",
          )}>
            <TopLevelItemLink
              item={item}
              className={itemClassName}
              onClick={() => setIsOpen(false)}
            />
          </div>

          <ul
            className={cn(
              "w-full container mx-auto flex flex-col sub-nav__list"
            )}
          >
            {subNav.map((item, index) => {
              let Component;
              switch (item.view_mode) {
                case "default":
                  Component = MainItemLink;
                  break;
                case "title":
                  Component = BoldItemLink;
                  break;
                case "merk_categorie":
                  Component = MerkCategorieItemLink;
                  break;
                case "merk":
                  Component = MerkItemLink;
                  break;
                default:
                  return null;
              }

              const hasViewModeMerk = (children) => {
                return children.some((child) => child.view_mode === "merk");
              };

              const isBoldWithChildren = (item.view_mode === "title" || item.view_mode === "default") && item.children?.some(child => child.view_mode === "default" || child.view_mode === "title");

              if (isBoldWithChildren) {
                return (
                  <ul key={`${item.url}-${index}`} className="sub-nav-default-nested">
                    <li>
                      <BoldItemLink item={item} className={""} onClick={() => setIsOpen(false)} />
                      <ul>
                        {item.children.map((child, childIndex) => {
                          if (child.view_mode === "title") {
                            return (
                              <BoldItemLink
                                key={`${child.url}-${childIndex}`}
                                item={child}
                                className={""}
                                onClick={() => setIsOpen(false)}
                              />
                            );
                          } else {
                            return (
                              <MainItemSubLink
                                key={`${child.url}-${childIndex}`}
                                item={child}
                                className={""}
                                onClick={() => setIsOpen(false)}
                              />
                            );
                          }
                        })}
                      </ul>
                    </li>
                  </ul>
                );
              }

              return (
                <Fragment key={`${item.url}-${index}`}>
                  <Component item={item} className={""} onClick={() => setIsOpen(false)} />
                  {item.children?.length > 0 && (
                    <ul
                      className={cn(
                        "sub-nav",
                        hasViewModeMerk(item.children)
                          ? "sub-nav-brands"
                          : "sub-nav-default"
                      )}
                    >
                      {item.children.map((child, childIndex) => {
                        let ChildComponent;
                        switch (child.view_mode) {
                          case "default":
                            ChildComponent = MainItemSubLink;
                            break;
                          case "title":
                            ChildComponent = BoldItemLink;
                            break;
                          case "merk_categorie":
                            ChildComponent = MerkCategorieItemLink;
                            break;
                          case "merk":
                            ChildComponent = MerkItemLink;
                            break;
                          default:
                            return null;
                        }
                        return (
                          <ChildComponent
                            key={`${child.url}-${childIndex}`}
                            item={child}
                            className={""}
                            onClick={() => setIsOpen(false)}
                          />
                        );
                      })}
                    </ul>
                  )}
                </Fragment>
              );
            })}
          </ul>
        </div>
      </Transition>
    </div>
  );
};

const TopLevelItemLink = ({ className, item, onClick }) => {
  const url = item?.url ? item.url : "/";
  return (
    <Link
      className={cn(
        "group inline-flex !text-lg font-bold w-full top-level-nav max-lg:border-b max-lg:border-dashed max-lg:pb-2",
        className
      )}
      href={url}
      onClick={onClick}
    >
      {item.title}
    </Link>
  );
};

const MainItemLink = ({ className, item, onClick }) => {
  const url = item?.url ? item.url : "/";
  return (
    <Link
      className={cn("group inline-flex items-center gap-2 lg:gap-1 max-lg:w-full max-lg:font-semibold max-lg:border-b max-lg:pb-4", className)}
      href={url}
      onClick={onClick}
    >
            <span className={"max-lg:flex max-lg:w-full max-lg:items-center"}>
                {item.title}
              <Icon
                name="arrow-vrt"
                className={cn("h-6 w-6 max-lg:ml-auto lg:h-4 lg:w-4 lg:rotate-90 lg:group-hover:-rotate-90 lg:transition-transform")}
              />
            </span>
    </Link>
  );
};

const MainItemSubLink = ({ className, item, onClick }) => {
  const url = item?.url ? item.url : "/";
  return (
    <Link
      className={cn("sub-nav__item sub-nav__item--default-link inline-flex items-center gap-2 text-sm group", className)}
      href={url}
      onClick={onClick}
    >
      <span className={"inline-flex items-center gap-2"}>{item.title}</span>
    </Link>
  );
};

const BoldItemLink = ({ className, item, onClick }) => {
  const url = item?.url ? item.url : "/";
  return (
    <Link
      className={cn(
        "sub-nav__item sub-nav__item--bold-title inline-flex items-center gap-2 w-full",
        className,
      )}
      href={url}
      onClick={onClick}
    >
      <span className="text-base font-bold">{item.title}</span>
    </Link>
  );
};

const MerkCategorieItemLink = ({ className, item, onClick }) => {
  return (
    <li key={item.url} className={"sub-nav__item sub-nav__item--brand-cat"} onClick={onClick}>
      <span>{item.title}</span>
    </li>
  );
};

const MerkItemLink = ({ className, item, onClick }) => {
  return (
    <li key={item.id} className={"sub-nav__item sub-nav__item--brand"} onClick={onClick}>
      <SvgLink svg={item.extras.logo} href={item.url} />
      <span>{item.title}</span>
    </li>
  );
};
