interface InputContainerProps {
  children;
  className?: string;
  error?: string;
  isError: boolean;
}

const InputContainer = ({
  children,
  className,
  isError,
  error,
}: InputContainerProps) => {
  return (
    <div
      className={className}
      data-error={isError ? "true" : undefined}
      suppressHydrationWarning={true}
    >
      {children}
      {error && <div className="form-error">{error}</div>}
    </div>
  );
};

export default InputContainer;
